<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <!-- ==========主要內容區========== -->
                        <v-container fluid fill-height  class="d-none d-sm-flex">
                                <v-row style="z-index:999;">
                                    <v-col>
                                        <div class="text-sm-h5 text-white opacity-8" style="height: 800px;">歡迎使用人員進出自動辨識系統，請點選上方選單進入各功能頁面。</div>
                                    </v-col>
                                </v-row>
                        </v-container>
                        <v-container fluid fill-height  class="d-none d-sm-flex" v-if="false">
                            <v-card elevation="24" outlined tile height="100%" width="100%" style="background-color: #e4e7ec;z-index:999">
                                <v-row >
                                    <v-col>
                                        <v-select
                                        v-model="jsSite"
                                        :items="jobSiteList"
                                        item-text="text"
                                        item-value="value"
                                        solo
                                        style="z-index:999;font-size: 18px;font-weight: bold;height:25px;"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" style="height:60%;">
                                        <v-card elevation="0" height="100%" width="100%" class="mt-4 ml-4" style="background-color: #e4e7ec;">
                                            <v-card-title class="justify-center" style="color: #039BE5;font-size: 40px;font-weight: bold;margin-top: 50px;">本日入場人數</v-card-title>
                                            <div style="color: #263959;font-size: 150px;text-align: center;font-weight: bold;margin-top: -20px;">{{dataList.totalPeople}}</div>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-card elevation="0" height="100%" width="100%" class="mt-4 ml-4" style="background-color: #e4e7ec;">
                                            <v-card-title class="justify-center" style="color: #039BE5;font-size: 40px;font-weight: bold;margin-top: 50px;">目前在場人數</v-card-title>
                                            <div style="color: #E53935;font-size: 150px;text-align: center;font-weight: bold;margin-top: -20px;">{{dataList.nowPeople}}</div>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card elevation="0" height="100%" width="100%" class="mt-4" style="background-color:#e4e7ec;">
                                            <v-card-title class="font-weight-black justify-center" style="font-size: 40px;color: rgb(38, 57, 89);margin-top: 0px;">{{dateStr}}</v-card-title>
                                            <v-card-title class="font-weight-black justify-center" style="font-size: 40px;color: rgb(38, 57, 89);margin-top: 0px;">{{timeStr}}</v-card-title>
                                            <v-card-title class="font-weight-black justify-center" style="font-size: 40px;color: rgb(38, 57, 89);margin-top: 0px;">{{city}}</v-card-title>
                                            <v-card-title class="font-weight-black justify-center" style="font-size: 30px;color: rgb(38, 57, 89);margin-top: 0px;" v-if="false">溫度<span style="color: #6f3826;margin-right: 30px;">{{temperature}}°C</span>濕度<span style="color: #6f3826;">{{humidity}}%</span></v-card-title>
                                            <table width="100%" style="margin-top: 50px;" v-if="checkPlusFunc('heatindexEnable')">
                                                <tr>
                                                    <td  colspan="4" style="text-align: center;" :class="heatColor">
                                                        <span class="font-weight-black" style="font-size: 30px;">
                                                            熱指數 {{heatindex}}<br/>
                                                            {{heatAlertText}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row style="margin-top: 0px;">
                                    <v-col cols="6">
                                        <v-card elevation="0" outlined tile min-height="350px" width="100%"  style="position: relative;">
                                            <v-card-title class="font-weight-black justify-center" style="font-size:30px; background-color:#D9D4CF;color:#3E4348;">最新五筆<span style="font-size: 30px;background-color:#D9D4CF;color:#C65146">入場</span>紀錄</v-card-title>
                                            <ul class="menu">
                                                <li v-for="(item, index) in dataList.recentIn" :key="item.ioTime + index">
                                                    <v-card class="ml-2" elevation="0" outlined tile height="300px" width="100%" style="background-color:#fffcf0;">
                                                        <img :src="'data:image/jpg;base64,' + item.photo" width="100%">
                                                        <div style="font-size: 18px;font-weight: bold; color:#1B5E20; text-align: center;">{{item.ioTime}}</div>
                                                        <div style="font-size: 24px;font-weight: bold; color:#263959; text-align: center;">{{item.name}}</div>
                                                        <div style="font-size: 14px;font-weight: bold; color:#EF6C00; text-align: center;" v-for="(item2, index2) in item.memberPropertyListText" :key="item.ioTime + item2 + index2">{{item2}}</div>
                                                    </v-card>
                                                </li>
                                            </ul>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-card elevation="0" height="100%" width="100%" outlined tile>
                                            <v-card-title class="font-weight-black justify-center" style="font-size: 30px; background-color:#D9D4CF;color:#3E4348;">最新五筆<span style="font-size:30px;background-color:#D9D4CF;color:#C65146">出場</span>紀錄</v-card-title>
                                            <ul class="menu">
                                                <li v-for="(item, index) in dataList.recentOut" :key="item.ioTime + index">
                                                    <v-card class="ml-2" elevation="0" outlined tile min-height="300px" width="100%" style="background-color:#fffcf0;">
                                                        <img :src="'data:image/jpg;base64,' + item.photo" height="100%" width="100%">
                                                        <div style="font-size: 18px;font-weight: bold; color:#1B5E20; text-align: center;">{{item.ioTime}}</div>
                                                        <div style="font-size: 24px;font-weight: bold; color:#263959; text-align: center;">{{item.name}}</div>
                                                        <div style="font-size: 14px;font-weight: bold; color:#EF6C00; text-align: center;" v-for="(item2, index2) in item.memberPropertyListText" :key="item.ioTime + item2 + index2">{{item2}}</div>
                                                    </v-card>
                                                </li>
                                            </ul>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-container>
                        <v-container class="d-flex d-sm-none">
                            <v-card elevation="24" outlined tile height="100%" width="100%" style="background-color: #e4e7ec;z-index:999">
                                <v-row >
                                    <v-col>
                                        <v-select
                                        v-model="jsSite"
                                        :items="jobSiteList"
                                        item-text="text"
                                        item-value="value"
                                        solo
                                        style="z-index:999;font-size: 18px;font-weight: bold;height:25px;"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card elevation="0" height="100%" width="100%" style="background-color: #e4e7ec;">
                                            <v-card-title class="justify-center" style="color: #E53935;font-size: 40px;font-weight: bold;">目前在場人數</v-card-title>
                                            <v-card-title class="justify-end mt-n5" style="color: #263959;font-size: 30px;font-weight: bold;">/本日入場人數</v-card-title>
                                            <div style="text-align: center;">
                                                <span style="color: #E53935;font-size: 100px;font-weight: bold;">{{dataList.nowPeople}}</span>
                                                <span style="color: #263959;font-size: 40px;font-weight: bold;">/{{dataList.totalPeople}}</span>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card elevation="0" height="100%" width="100%" class="mt-4" style="background-color:#e4e7ec;">
                                            <v-card-title class="font-weight-black justify-center" style="font-size: 40px;color: rgb(38, 57, 89);margin-top: 0px;">{{city}}</v-card-title>
                                            <table width="100%" v-if="checkPlusFunc('heatindexEnable')">
                                                <tr>
                                                    <td  colspan="4" style="text-align: center;" :class="heatColor">
                                                        <span class="font-weight-black" style="font-size: 30px;">
                                                            熱指數 {{heatindex}}<br/>
                                                            {{heatAlertText}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-container>
                        <!-- ==========主要內容區========== -->

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import {inOutInfo, getSysParamListByCode, getJobSiteList, getHeatindex} from "@/api/hyirecoApi"
import {getWeatherInfo} from "@/api/weatherApi"

export default {
  name: 'homepage',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    this.auth = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        }
    };
  },
  destroyed() {
    clearInterval(this.tiemTimer);
    this.tiemTimer = null;
    clearInterval(this.dataTimer);
    this.dataTimer = null;
  },
  mounted(){
    this.userName = this.loginAuth.memberName;
    this.getJSList();
    this.getMemberProperty();
    this.getDataList();
    this.getCityData();
    this.showDateTime();
    this.getWeather();
    this.getHeatColor();

    this.tiemTimer = setInterval(() => {
        this.getWeatherRefresh();
        this.showDateTime();
    },1000);

    this.dataTimer =  setInterval(() => {
        this.getDataList();
    },10000);
  },
  methods: {
    getWeatherRefresh: function(){
        var date = new Date();
        var mm = date.getMonth() + 1;

        if (this.nowHour != mm) {
            this.nowHour = mm;
            this.getWeather();
        }
    },
    getWeather: function(){
        var vm = this;
        var date = new Date();
        var hour = parseInt(date.getHours()/3)*3;

        var dateStr = [
            date.getFullYear(),
            ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1),
            (date.getDate() > 9 ? '' : '0') + date.getDate()
        ].join('-');

        var requestStr = "";
        requestStr += "locationName=" + vm.city;
        requestStr += "&elementName=T,RH";
        requestStr += "&timeFrom=" + dateStr + "T" + ((hour > 9 ? '' : '0') + hour.toString()) + ":00:00"

        getWeatherInfo(requestStr).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data.records.locations[0].location[0].weatherElement;
                
                var T = responseData.filter(function(item) {
                    return item.elementName === 'T';
                })[0].time[0].elementValue[0].value;

                var RH = responseData.filter(function(item) {
                    return item.elementName === 'RH';
                })[0].time[0].elementValue[0].value;

                vm.temperature = T;
                vm.humidity = RH;
                console.log("T:" + T + ",RH:" + RH);

                if (parseFloat(T) >= 26.7) {
                    var para = {
                        temperature: T,
                        humidity: RH
                    };
                    
                    getHeatindex(para, vm.auth).then(function(response) {
                        if ("200" == response.status) {
                            var responseData = response.data;
                            //console.log("responseData = " + JSON.stringify(responseData));
                            //vm.heatindex
                            if (responseData.statusCode === "1") {
                                vm.heatindex = responseData.heatindex;
                                console.log("heatindex = " + vm.heatindex);
                            }
                            vm.getHeatColor();
                        }
                    }); 
                } else {
                    vm.heatindex = "32.2";
                    vm.getHeatColor();
                }

            }
        });
    },
    getHeatColor: function(){
        var vm = this;
        var intHeat = parseFloat(vm.heatindex);
        if (intHeat < 32.2) {
            vm.heatColor = "light-green accent-2";
            vm.heatAlertText = "注意";
        } else if (intHeat < 40.6) {
            vm.heatColor = "yellow accent-2";
            vm.heatAlertText = "格外注意";
        } else if (intHeat < 54.4) {
            vm.heatColor = "orange accent-2";
            vm.heatAlertText = "危險";
        } else if (intHeat >= 54.4) {
            vm.heatColor = "deep-orange accent-2";
            vm.heatAlertText = "極度危險";
        }
    },
    getDataList: function() { 
        var vm = this;
        inOutInfo(vm.jsSite, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("responseData = " + JSON.stringify(responseData));
                if (responseData.statusCode === "1") {
                    //console.log("vm.memberPropertyList = " + JSON.stringify(vm.memberPropertyList));
                    vm.dataList = responseData;
                    if (vm.memberPropertyList.length > 0) {

                        vm.dataList.recentIn = vm.dataList.recentIn.map(function(item) {
                            var tmpArr = item.memberPropertyList.map(function(item2){
                                var tmpP = vm.memberPropertyList.find(function(item3){
                                    return item2 === item3.value;
                                });
                                return tmpP.text;
                            });

                            var tmpTime = item.ioTime.split(" ");
                            item.ioTime = tmpTime.length > 1 ? tmpTime[1] : item.ioTime;

                            item.memberPropertyListText = tmpArr;
                            return item;
                        });

                        vm.dataList.recentOut = vm.dataList.recentOut.map(function(item) {
                            var tmpArr = item.memberPropertyList.map(function(item2){
                                var tmpP = vm.memberPropertyList.find(function(item3){
                                    return item2 === item3.value;
                                });
                                return tmpP.text;
                            });

                            var tmpTime = item.ioTime.split(" ");
                            item.ioTime = tmpTime.length > 1 ? tmpTime[1] : item.ioTime;
                            item.memberPropertyListText = tmpArr;
                            return item;
                        });
                    }


                    console.log("responseData = " + JSON.stringify(vm.dataList));
                }
            }
        });
    },
    getCityData: function() {
        var tmpArr = this.loginAuth.orgnization.location.split(' ');
        this.city = tmpArr.length > 0 ? tmpArr[0] : "";
        //console.log("city = " + tmpArr);
    },
    showDateTime: function() {
        var date = new Date();
        var mm = date.getMonth() + 1;
        var dd = date.getDate();

        this.dateStr = [
            date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
        ].join('-');


        this.timeStr = [
            (date.getHours() > 9 ? '' : "0") + date.getHours(),
            (date.getMinutes() > 9 ? '' : "0") + date.getMinutes(),
            (date.getSeconds() > 9 ? '' : "0") + date.getSeconds()
        ].join(':');

    },
    getMemberProperty: function(){
        var vm = this;
        getSysParamListByCode("people",vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                if (responseData.statusCode === "1") {
                    responseData.sysParam.childParamList.forEach(function(para){
                        var tmpArr =  para.childParamList.map(function(child){
                                        var item = new Object();
                                        item.text = para.name + "-" + child.name;
                                        item.value = child.code;
                                        return item;
                                        });
                        vm.memberPropertyList = vm.memberPropertyList.concat(tmpArr);
                        console.log("tmpArr = " + JSON.stringify(vm.memberPropertyList));
                    });
                }
            }
        });
    },
    getJSList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        getJobSiteList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                console.log("responseData = " + JSON.stringify(responseData));
                if (responseData.statusCode === "1") {
                    var data = responseData.queryPager.resultList;
                    
                    vm.jobSiteList = data.map(function(item) {
                        var obj = new Object();
                        obj.text = item.jsName;
                        obj.value = item.jsId;

                        return obj;
                    });

                    if (vm.jobSiteList.length > 0) {
                        vm.jsSite = vm.jobSiteList[0].value;
                    }

                } else {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
            vm.isLoading = false;
        });
       
    },
  },
  watch: {
    jsSite : function(){
        this.getDataList();
    },
  },
  data: () => ({
    queryRequest: {
        queryObject: {
            jsName: "",
            status: "",
        },
        queryPager: {
            currentPage: 1,
            pageSize: 999,
        },
    },
    userName: "",
    dataList: {},
    city: "",
    dateStr: "",
    timeStr: "",
    dataTimer: null,
    tiemTimer: null,
    memberPropertyList: [],
    jobSiteList:[],
    jsSite: "",
    temperature:"",
    humidity: "",
    heatColor: "",
    heatindex: "",
    heatAlertText:"",
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
  },
}
</script>

<style scoped>
    .hotDetal{
        text-align: center;
        font-size: 30px;
    }

    .column{
        float:left;
        list-style:none; /* 將預設的列表符號去掉 */
        padding:0; /* 將預設的內邊距去掉 */
        margin:0; /* 將預設的外邊距去掉 */
        width: 20%;
        height: 300px;
        min-height: 300px;
    }

    .menu{
        margin: 0;
        padding: 0;
        display:inline-block;
        margin-left: -10px;
        margin-right: 5px;

    }

    .menu li { 
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        padding-left: 5px;
        padding-right: 5px;
        width: 20%;
    }
</style>